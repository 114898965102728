import type {HTMLProps, ReactNode, ButtonHTMLAttributes} from 'react'
import {forwardRef} from 'react'
import cx from 'classnames'

type ButtonSize = 'sm' | 'regular'

type ButtonVariant = 'primary' | 'regular' | 'plain' | 'ghost'

type ButtonProps = {
  children: ReactNode
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  size?: ButtonSize
  variant?: ButtonVariant
  loading?: boolean
} & Omit<HTMLProps<HTMLButtonElement>, 'size'>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      size = 'regular',
      variant = 'primary',
      loading = false,
      ...rest
    },
    ref,
  ) => {
    const classNames = cx(
      'relative rounded-md outline-none overflow-hidden focus:ring-2 focus:ring-offset-1 whitespace-nowrap group',
      'after:absolute after:left-[0.5px] after:right-[0.5px] after:top-[0.5px] after:h-full after:rounded-md after:border-t after:border-l after:border-r after:z-10',
      'before:absolute before:w-full before:h-2 before:left-0 before:bottom-0 before:z-20 before:bg-gradient-to-t before:to-transparent',
      {
        'text-sm pt-1.5 pb-1 px-4': size === 'regular',
        'text-xs font-medium pt-1.5 pb-1 px-2': size === 'sm',
        'bg-brand focus:ring-brand focus:ring-opacity-35 hover:bg-[#101BDE] focus:bg-[#101BDE] hover:before:from-[#101BDE] focus:before:from-[#101BDE] before:from-brand after:border-[#6C73FF] text-white':
          variant === 'primary',
        'bg-black after:border-gray-600 focus:ring-gray-300 before:from-black text-white':
          variant === 'regular',
        'bg-gray-200 text-black after:border-gray-100 focus:ring-gray-300 before:from-gray-200 hover:bg-gray-300':
          variant === 'plain',
        'bg-transparent !text-black px-0 after:border-transparent focus:ring-gray-300 before:from-transparent hover:bg-gray-100 [tabindex="-1"]:ring-2':
          variant === 'ghost',
      },
      className,
    )

    return (
      <button
        {...rest}
        ref={ref}
        className={classNames}
        disabled={loading || rest.disabled}
      >
        <span
          className={cx(
            'flex items-center gap-1.5 group-active:translate-y-[1px]',
            {
              'text-transparent pointer-events-none': loading,
            },
          )}
        >
          {loading && (
            <span className="block w-4 h-4 border-2 border-white rounded-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
              <span className="block w-4 h-4 border-t-2 border-l-2 border-brand/60 rounded-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 animate-loader" />
            </span>
          )}
          {children}
        </span>
      </button>
    )
  },
)
