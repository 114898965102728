import {useEffect} from 'react'
import {useQuery} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'

import {useDateFilteredEmails} from '~/components/Email/hooks/useEmails'
import {useFeedContext} from '~/routes/Dashboard/context'
import {fetcher, queryClient} from '~/utilities/queries'
import {useAuthContext} from '~/providers/Auth'
import {Emails} from '~/components/Email/Emails'

import {AllTimeFeed} from './components/AllTimeFeed/AllTimeFeed'
import {FeedEmptyState} from '../../components/Feed/FeedEmptyState'

export async function getEmails() {
  const emails = await fetcher<Email[]>('/api/emails')

  for (const email of emails) {
    queryClient.setQueryData(['email', email.id.toString()], {email})
  }

  return emails
}

export function Home() {
  const {data} = useQuery<Email[]>({
    queryKey: ['homeEmails'],
  })
  const {t} = useTranslation('inbox')
  const [_, setFeedContext] = useFeedContext()
  const {currentUser} = useAuthContext()
  const {
    filteredEmails,
    emailsFromToday,
    emailsFromYesterday,
    emailsFromThisWeek,
    allOtherEmails,
    noEmails,
  } = useDateFilteredEmails(data)

  const quickSelect = currentUser!.settings.dateRange

  useEffect(() => {
    setFeedContext((value) => ({
      ...value,
      enableCustomScrollbar: !noEmails,
    }))
  }, [noEmails])

  if (noEmails) {
    return (
      <FeedEmptyState
        title={
          currentUser!.settings.allTime
            ? t('done')
            : quickSelect
              ? t('doneRange', {range: t(quickSelect).toLocaleLowerCase()})
              : t('done')
        }
      />
    )
  }

  if (currentUser!.settings.allTime) {
    return (
      <AllTimeFeed
        emailsFromToday={emailsFromToday}
        emailsFromYesterday={emailsFromYesterday}
        emailsFromThisWeek={emailsFromThisWeek}
        allOtherEmails={allOtherEmails}
      />
    )
  }

  return (
    <Emails
      grouped={currentUser!.settings.feedGrouping === 'grouped'}
      emails={filteredEmails}
    />
  )
}
