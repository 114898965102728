import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import set from 'lodash/set'
import {isThisWeek, isToday, isWithinInterval, isYesterday} from 'date-fns'

import {useAuthContext} from '~/providers/Auth'

import {getEmail} from '../EmailPreview'
import {useTopic} from '~/components/Inbox/routes/Topic/hooks'
import {useSub} from '~/components/Inbox/routes/Sub/hooks'
import {dateRangeFromQuickSelect} from '~/components/Inbox/components/Feed/components/FeedNavbar/GlobalSettings/components/SettingsDatePicker/utils'

export type GroupedEmails = {[key: string]: Email[]}

export function sortEmails(emails: Email[]) {
  return emails.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )
}

export function groupEmailsBySub(emails: Email[]) {
  return emails.reduce<GroupedEmails>((acc, email) => {
    acc[`sub_${email.sender.id}`] = [
      ...(acc[`sub_${email.sender.id}`] || []),
      email,
    ]

    return acc
  }, {})
}

export function emailsByTopic(emails: Email[]) {
  return emails.reduce(
    (acc, email) => {
      for (const topic of email.sender.topics) {
        set(acc, `${topic.name}`, [...(acc[topic.name] || []), email])
      }

      return acc
    },
    {} as {[key: string]: Email[]},
  )
}

export function filterReadUnreadEmails(
  emails: Email[],
  userSettings: UserSettings,
) {
  if (!emails || emails?.length === 0) return []

  const {feedView} = userSettings

  return emails.filter((email) => {
    if (feedView === 'unread') {
      return !email.read
    } else if (feedView === 'read') {
      return email.read
    }

    return true
  })
}

export function filterDateIntervalEmails(
  emails: Email[],
  userSettings: UserSettings,
) {
  if (!emails || emails?.length === 0) return []
  if (userSettings.allTime) return emails

  const dateRange = dateRangeFromQuickSelect(userSettings.dateRange)

  return emails.filter((email) =>
    isWithinInterval(new Date(email.createdAt), {
      start: dateRange.from ?? new Date(),
      end: dateRange.to ?? new Date(),
    }),
  )
}

export function filterEmails(emails: Email[] = [], userSettings: UserSettings) {
  if (!emails || emails?.length === 0) return []

  return filterDateIntervalEmails(
    filterReadUnreadEmails(emails, userSettings),
    userSettings,
  )
}

export function useEmails() {
  const {currentUser} = useAuthContext()
  const params = useParams<{slug?: string; topicName?: string}>()
  const sub = params.slug ? useSub() : null
  const {topic, emails: topicEmails} = params.topicName
    ? useTopic()
    : {topic: null, emails: []}
  const {data: homeEmails} = useQuery<Email[]>({queryKey: ['homeEmails']})

  const emails: Email[] = useMemo(() => {
    if (sub !== null) return sortEmails(sub.emails)
    if (topic !== null) {
      return sortEmails(topicEmails)
    }
    if (homeEmails) {
      return filterEmails(sortEmails(homeEmails), currentUser!.settings)
    }

    return []
  }, [sub, topic, homeEmails])

  return emails
}

export function useDateFilteredEmails(emails?: Email[]) {
  const {currentUser} = useAuthContext()
  const filteredFeedEmails = useMemo(() => {
    if (!emails) {
      return {
        emailsFromToday: [],
        emailsFromYesterday: [],
        emailsFromThisWeek: [],
        allOtherEmails: [],
        filteredEmails: [],
      }
    }

    const filteredEmails = filterEmails(emails, currentUser!.settings)
    const quickSelect = currentUser!.settings.dateRange

    const emailsFromToday =
      quickSelect !== 'today'
        ? filterReadUnreadEmails(
            emails.filter((email) => isToday(email.createdAt)) || [],
            currentUser!.settings,
          )
        : []
    const emailsFromYesterday =
      quickSelect !== 'yesterday'
        ? filterReadUnreadEmails(
            emails.filter((email) => isYesterday(email.createdAt)) || [],
            currentUser!.settings,
          )
        : []
    const emailsFromThisWeek =
      quickSelect !== 'thisWeek'
        ? filterReadUnreadEmails(
            emails.filter(
              (email) =>
                !emailsFromToday.map((e) => e.id).includes(email.id) &&
                !emailsFromYesterday?.map((e) => e.id).includes(email.id) &&
                isThisWeek(email.createdAt),
            ) || [],
            currentUser!.settings,
          )
        : []
    const allOtherEmails = filterReadUnreadEmails(
      emails.filter(
        (email) =>
          !emailsFromToday.map((e) => e.id).includes(email.id) &&
          !emailsFromYesterday?.map((e) => e.id).includes(email.id) &&
          !emailsFromThisWeek?.map((e) => e.id).includes(email.id),
      ) || [],
      currentUser!.settings,
    )

    return {
      emailsFromToday,
      emailsFromYesterday,
      emailsFromThisWeek,
      allOtherEmails,
      filteredEmails,
    }
  }, [emails, currentUser!.settings])

  const {
    filteredEmails,
    emailsFromToday,
    emailsFromYesterday,
    emailsFromThisWeek,
    allOtherEmails,
  } = filteredFeedEmails

  const noEmails = useMemo(() => {
    return currentUser!.settings.allTime
      ? emailsFromYesterday.length === 0 &&
          emailsFromThisWeek.length === 0 &&
          allOtherEmails.length === 0 &&
          emailsFromToday.length === 0
      : filteredEmails.length === 0
  }, [
    emailsFromToday,
    emailsFromYesterday,
    emailsFromThisWeek,
    allOtherEmails,
    currentUser!.settings.allTime,
  ])

  return {...filteredFeedEmails, noEmails}
}

type EmailFetcherOptions = {
  skipQuery?: boolean
  refetchOnWindowFocus?: boolean
} & Omit<UseQueryOptions<{email: Email}>, 'queryKey' | 'queryFn' | 'enabled'>

export function useEmailFetcher({
  skipQuery,
  ...options
}: EmailFetcherOptions = {}) {
  const params = useParams<{emailId: string}>()

  return useQuery<{email: Email}>({
    queryKey: ['email', params.emailId],
    queryFn: async () => await getEmail(params.emailId!),
    enabled: !skipQuery,
    ...options,
  })
}
