import {startOfDay, endOfDay, startOfWeek, isEqual, subDays} from 'date-fns'

function getDateRanges() {
  const today = new Date()
  const startOfToday = startOfDay(today)
  const endOfToday = endOfDay(today)

  const yesterday = subDays(today, 1)
  const startOfYesterday = startOfDay(yesterday)
  const endOfYesterday = endOfDay(yesterday)

  return {
    today: {from: startOfToday, to: endOfToday},
    yesterday: {from: startOfYesterday, to: endOfYesterday},
    thisWeek: {from: startOfWeek(today), to: endOfToday},
  }
}

export function quickSelectFromDateRange({
  from,
  to,
}: {
  from: Date
  to: Date
}): DateRangeSetting | undefined {
  if (!from || !to) return
  const {today, yesterday, thisWeek} = getDateRanges()

  // Check for "today"
  if (
    isEqual(startOfDay(from), today.from) &&
    isEqual(endOfDay(to), today.to)
  ) {
    return 'today'
  }

  // Check for "yesterday"
  if (
    isEqual(startOfDay(from), yesterday.from) &&
    isEqual(endOfDay(to), yesterday.to)
  ) {
    return 'yesterday'
  }

  // Check for "thisWeek"
  if (
    isEqual(startOfWeek(from), thisWeek.from) &&
    isEqual(endOfDay(to), today.to)
  ) {
    return 'thisWeek'
  }
}

export function dateRangeFromQuickSelect(
  value?: DateRangeSetting,
  previousValue?: DateRangeSetting,
): {from: Date; to: Date} | undefined {
  const {today, yesterday, thisWeek} = getDateRanges()

  switch (previousValue || value) {
    case 'today': {
      return {from: today.from, to: today.to}
    }
    case 'yesterday': {
      return {from: yesterday.from, to: yesterday.to}
    }
    case 'thisWeek': {
      return {from: thisWeek.from, to: thisWeek.to}
    }
    default:
      return undefined
  }
}
