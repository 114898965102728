import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'

import {useAuthContext} from '~/providers/Auth'
import {filterEmails} from '~/components/Email/hooks/useEmails'

export function useTopics() {
  const {data: homeEmails} = useQuery<Email[]>({
    queryKey: ['homeEmails'],
  })
  const topics = useMemo(() => {
    if (!homeEmails) return []

    const topics = homeEmails.map((email) => email.sender.topics).flat()
    const uniqueTopics = topics.filter(
      (topic, index, array) =>
        array.findIndex((t) => t.name === topic.name) === index,
    )

    return uniqueTopics
  }, [homeEmails])

  return topics
}

export function useTopic(): {topic: Tag | null; emails: Email[]} {
  const params = useParams<{topicName: string}>()
  const {currentUser} = useAuthContext()
  const topics = useTopics()
  const {data: homeEmails} = useQuery<Email[]>({
    queryKey: ['homeEmails'],
  })
  const topic = useMemo(
    () => topics.find((topic) => topic.name === params.topicName) ?? null,
    [topics, params.topicName],
  )
  const topicEmails = useMemo(() => {
    if (!homeEmails || !topic) return []

    return filterEmails(
      homeEmails.filter((email) =>
        email.sender.topics.map(({name}) => name).includes(topic.name),
      ),
      currentUser!.settings,
    )
  }, [homeEmails, topic, currentUser!.settings])

  return {
    topic,
    emails: topicEmails,
  }
}
