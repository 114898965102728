import type {ReactNode} from 'react'
import cx from 'classnames'

type FeedEmptyStateProps = {
  title: string
  children?: ReactNode
  fullHeight?: boolean
  icon?: ReactNode
}

export function FeedEmptyState({
  title,
  children,
  fullHeight = true,
  icon,
}: FeedEmptyStateProps) {
  return (
    <div
      className={cx(
        'rounded-xl bg-white flex flex-col items-center justify-center gap-2 px-8 xl:px-0',
        {
          'h-full': fullHeight,
          'h-auto py-32': !fullHeight,
        },
      )}
    >
      {icon}
      <h1 className="text-2xl text-center">{title}</h1>
      {children}
    </div>
  )
}
