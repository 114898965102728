import {useTranslation} from 'react-i18next'
import {CheckIcon} from '@heroicons/react/24/outline'

import {Emails} from '~/components/Email/Emails'
import {useAuthContext} from '~/providers/Auth'

type AllTimeFeedProps = {
  emailsFromToday: Email[]
  emailsFromYesterday: Email[]
  emailsFromThisWeek: Email[]
  allOtherEmails: Email[]
}

type HeadingProps = {
  title: string
  emails: Email[]
}

export function Heading({title, emails}: HeadingProps) {
  return (
    <h2 className="flex justify-between items-center w-full text-xl pt-4 pb-4 font-medium sticky top-8 bg-surface z-30">
      {title}
      {emails.length === 0 && (
        <>
          <span className="flex-1 h-0.5 bg-gray-300 mx-8" />
          <CheckIcon className="w-4 h-4 text-brand" strokeWidth={3} />
        </>
      )}
    </h2>
  )
}

export function AllTimeFeed({
  emailsFromToday,
  emailsFromYesterday,
  emailsFromThisWeek,
  allOtherEmails,
}: AllTimeFeedProps) {
  const {t} = useTranslation('inbox')
  const {currentUser} = useAuthContext()

  return (
    <>
      <Heading title={t('today')} emails={emailsFromToday} />
      <Emails
        grouped={currentUser!.settings.feedGrouping === 'grouped'}
        emails={emailsFromToday}
      />
      <Heading title={t('yesterday')} emails={emailsFromYesterday} />
      <Emails
        grouped={currentUser!.settings.feedGrouping === 'grouped'}
        emails={emailsFromYesterday}
      />
      <Heading title={t('thisWeek')} emails={emailsFromThisWeek} />
      <Emails
        grouped={currentUser!.settings.feedGrouping === 'grouped'}
        emails={emailsFromThisWeek}
      />
      <Heading title={t('older')} emails={allOtherEmails} />
      <Emails
        grouped={currentUser!.settings.feedGrouping === 'grouped'}
        emails={allOtherEmails}
      />
    </>
  )
}
