import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {type QueryObserverOptions, useQuery} from '@tanstack/react-query'

import {fetcher} from '~/utilities/queries'
import {useAuthContext} from '~/providers/Auth'

async function getArchivedSubEmails(slug: string) {
  return await fetcher(`/api/subs/${slug}/archived_emails`)
}

export function useSubs() {
  const {data: homeEmails} = useQuery<Email[]>({
    queryKey: ['homeEmails'],
  })
  const subs = useMemo(() => {
    if (!homeEmails) return []

    return homeEmails
      .map((email) => email.sender)
      .filter(
        (sub, index, array) =>
          array.findIndex(({name}) => name === sub.name) === index,
      )
  }, [homeEmails])

  return subs
}

export function useSub(): Sub | null {
  const params = useParams<{slug: string}>()
  const {currentUser} = useAuthContext()
  const subs = useSubs()
  const {data: homeEmails} = useQuery<Email[]>({
    queryKey: ['homeEmails'],
  })

  const sub = useMemo(
    () => subs?.find((sub) => sub.slug === params.slug),
    [subs, params.slug],
  )
  const subEmails = useMemo(() => {
    if (!homeEmails) return []

    return homeEmails.filter((email) => email.sender.id === sub?.id)
  }, [homeEmails, sub?.id, currentUser!.settings])

  if (!sub) return null

  return {
    ...sub,
    emails: subEmails,
  }
}

export function useArchivedSubEmails(
  queryOptions: Omit<
    QueryObserverOptions<{emails: Email[]}>,
    'queryKey' | 'queryFn'
  > = {},
) {
  const params = useParams<{slug: string}>()
  return useQuery<{emails: Email[]}>({
    queryKey: ['sub', params.slug, 'archivedEmails'],
    queryFn: async () => await getArchivedSubEmails(params.slug!),
    enabled: false,
    ...queryOptions,
  })
}
