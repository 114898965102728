import {createContext, useContext, useMemo, type ReactNode} from 'react'
import {redirect} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import {useLocalStorage} from '@uidotdev/usehooks'

import {fetcher, queryClient} from '~/utilities/queries'
import {AUTH_TOKEN} from '~/utilities/constants'

type AuthContext = {
  currentUser?: null | User
}

export const authContent = createContext<AuthContext>({
  currentUser: null,
})

const {Provider} = authContent

type AuthProviderProps = {
  children: ReactNode
}

export async function getCurrentUser() {
  return await fetcher('/api/users/current')
}

export async function setCurrentUserSettings(
  settings: Partial<User['settings']>,
) {
  // Optimistic FE update.
  queryClient.setQueryData(['currentUser'], (user: User) => ({
    ...user,
    settings: {...user.settings, ...settings},
  }))

  return await fetcher('/api/users/update_settings', {
    method: 'PUT',
    body: {settings},
  })
}

export async function currentUserOrRedirect(check: 'loggedIn' | 'loggedOut') {
  try {
    const user = (await queryClient.fetchQuery({
      queryKey: ['currentUser'],
      queryFn: getCurrentUser,
    })) as User

    return check === 'loggedIn' ? user : redirect('/')
  } catch {
    return check === 'loggedOut' ? null : redirect('/login')
  }
}

function parseCurrentUser(user?: User) {
  if (!user) return null

  return {
    ...user,
    settings: {
      ...user.settings,
      dateRange: user.settings.dateRange || 'today',
    },
  }
}

export function AuthProvider({children}: AuthProviderProps) {
  const {data} = useQuery<User>({queryKey: ['currentUser']})

  const currentUser = useMemo(() => parseCurrentUser(data), [data])

  return <Provider value={{currentUser}}>{children}</Provider>
}

export function useAuthContext() {
  return useContext(authContent)
}

export function useAuthToken() {
  return useLocalStorage<string | null>(AUTH_TOKEN, null)
}
